.difi-button {
}

.difi-button:hover {
     background: #d9d9d9;
     border-color: transparent;
 }

#root .wide-banner .banner-button:hover {
    background: rgb(6, 198, 249) !important;
    border-color: rgb(6, 198, 249) !important;
}

button.difi-button:hover:disabled {
    background: white !important;
    color: #343a40;
    border-color: #343a40 !important;
}

.integration-button {
    margin-left: 30px;
    margin-top: 15px;
}

.wide-banner {
    background: #dcf4fe;
    width: 100%;
}

.form-checkbox-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
}

.form-checkbox-icon {
    margin-left: 4px;
    margin-top: 5px;
}

.sp-web.show.popover {
    max-width: 405px;
}

/* reset style that bleeds over from main site */

body div.fade.sp-web.modal.show div.modal-body input,
body div.fade.sp-web.modal.show div.modal-body textarea,
#root *, .sp-web {
    font-size: 14px;
}

.sp-web button {
    font-size: 10px !important;
}

.sp-web .btn-outline-dark:hover {
    color: #000;
    background-color: #d9d9d9 !important;
    border-color: transparent;
}

#root > div.wide-banner.p-5.mb-4 > div > button.difi-button.p-2.font-weight-bold.small.m-1.btn.btn-outline-dark {
    background: #d9d9d9;
    border-color: transparent;
}

#root > div.wide-banner.p-5.mb-4 > div > button:hover {
    background-color: rgb(6, 198, 249) !important;
    border-color: rgb(6, 198, 249) !important;
}


#root .h1 {
    font-size: 20px;
    font-weight: bold;
}

#root .h1 svg {
    margin-right: 10px;
    vertical-align: top;
}

.sp-web label {
    display: inline-block;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.sp-web #content {
    padding: 20px;
}


body div.fade.sp-web.modal.show div.modal-body input[type='checkbox'],
#root form input[type='checkbox'],
#root form input[readonly][type='checkbox'] {
    -webkit-appearance: checkbox !important;
}

.no-br {
    white-space: nowrap
}
.form-control-plaintext {
    height: 34px;
}

textarea.form-control-plaintext {
    height: auto;
}


#root.sp-web > form  div.row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

#root > div.wide-banner > div > a {
    border-bottom: none;
}
#root form select {
    padding: 4px 8px 4px 8px !important;
}

#root form select[readonly]:hover,
#root form input[readonly]:hover {
    background: unset;
}
#root form select[readonly],
#root form input[readonly]  {
    padding: 4px 8px 4px 8px !important;
    border: 1px white !important;
    -webkit-appearance: unset !important;
}
#root > form > div.ml-0.mr-0.container > div > div:nth-child(1) > div:nth-child(4) > div > textarea[readonly],
#root form textarea[readonly] {
    padding: 8px 12px 8px 12px !important;
    border: 1px white !important;
    border-style: solid;
    resize: none !important;
}

.sp-web .form-check-input {
    padding-right: 1em;
    margin-left: 0;
}

body div.fade.sp-web.modal.show div.modal-body .form-check-label {
    padding-left: 1em;
}

.sp-web .form-check-label {
    padding-left: 2em;
}

.sp-web .form-check {
    padding-left: 1em;
}

#root form textarea[readonly]:hover {
    /*border: 0 !important;*/
    background: unset;
}

#root > form > div {
    padding-left: 0;
    padding-right: 0;
}

.content-article #content>article .sp-web a:hover {
    background-color: unset;
    box-shadow: unset;
}

body > div.fade.sp-web.modal.show > div {
    /*-webkit-transform: unset !important;*/
    -webkit-transform: translate(0, -15%) !important;
    max-width: 600px;
}

body > div.modal-backdrop.show {
    opacity: 0.5;
}

body > div.fade.sp-web.modal.show {
    opacity: 1;
    font-size: 14px;
}

body > div.fade.sp-web.modal.show > div > div > div.modal-body > div > textarea,
body > div.fade.sp-web.modal.show > div > div > div.modal-body > div > input {
    font-size: 14px;
}

body div.fade.sp-web.modal.show.scopes-modal div.modal-body {
    height: 400px;
    overflow: auto;
}

body div.fade.sp-web.modal.show div.modal-content {
    /*margin-top: 10rem;*/
    /*margin-bottom: auto;*/
}

body div.fade.sp-web.modal.show div.modal-dialog {
    margin-top: 100px;
    margin-bottom: auto;
}

body > div.fade.sp-web.popover.show {
    opacity: 1;
    font-size: 14px;
}

#root > div.container > div > div > h2 {
    margin-top: 0;
    margin-bottom: 0;
}

#content > article > div > div.button.custom > a:hover {
    background-color: #d9d9d9;
    border-color: transparent;
}

.sp-web li {
    margin-bottom: 0 !important;
}

.form-control.is-invalid {
    border-color: #ced4da !important;
    background-image: none !important;
}

.was-validated .sp-web .form-control:invalid:focus, .sp-web .form-control.is-invalid:focus {
    border-color: #8bbafe;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
    background-color: #fff;
    color: #495057;
}

#integration-form div.ml-0.mr-0.container div.col div.invalid-feedback,
.form-control.is-invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip, .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control:invalid ~ .invalid-tooltip {
    display: block;
}

.was-validated .sp-web .form-check-input:valid ~ .form-check-label, .sp-web .form-check-input.is-valid ~ .form-check-label {
    color: unset;
}

/* force left-side menu disappear earlier */
@media (max-width: 1240px) {
    #main.with-sidebar #sidebar {
        padding-right: 30px !important;
        padding-left: 30px !important;
        display: none !important;
    }

    .col-md-9, #main.with-sidebar #content, #main.with-sidebar.with-aside #content, #main.with-sidebar.with-aside aside {
        width: 100% !important;
    }
}

/* restore stuff that seems to have been filtered out? */
@media (min-width: 767px) {
    #edit-search-block-form--2,
    header .tools form input[type='text'] {
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
        -webkit-transition: all .3s ease 0s;
        -moz-transition: all .3s ease 0s;
        -ms-transition: all .3s ease 0s;
        -o-transition: all .3s ease 0s;
        transition: all .3s ease 0s;
        padding: 7px 0px 7px 8px;
        font-size: .7em;
        font-weight: 400;
        background-color: #ccd8e4;
        border: none;
        outline: 0;
        -webkit-appearance: none;
        width: 150px;
        text-indent: 1.5%;
        color: #000;
    }
}

@media (min-width: 767px) {
    #edit-search-block-form--2:hover,
    header .tools form input[type='text']:hover, header .tools form input[type='text']:active, header .tools form input[type='text']:focus {
        width: 220px;
        background-color: #C2D6DE;
    }
}

.sp-web .card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(255,255,255,1);
    border-bottom: 1px solid rgba(48,49,50,0.54);
    font-weight: bold;
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
}

.sp-web.card-row {
    margin-left: 0px;
}

.sp-web .card {
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(48,49,50,0.54);
    border-radius: .5rem;
    min-width: 310px;
    min-height: 160px;
    margin-right: 15px;
    margin-bottom: 15px;

}

.sp-web .card-title {
    margin-bottom: 0.75rem;
    font-size: 18px !important;
    font-weight: 500;
    line-height: 1.2;
}

.sp-web .card-icon {
    margin-right: 5px;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #dc3545 !important;
}

/* DifiInput */
.difi-input {
    line-height: normal;
    margin-bottom: 0.5em;
    margin-right: 0.5em;
}


.difi-input label {
    margin: 0 !important;
    color: grey !important;
    font-size: .7em !important;
    font-weight: normal !important;
    /*display: inline-block;*/
}

#main .difi-input  input[type="text"] {
    background: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    /*display: inline-block;*/
    padding: 0 !important;
    width: 100%;
    width: -moz-available;
    /*width: -webkit-fill-available;*/
    /*width: fill-available;*/
    width: stretch;
}

/* DifiList and friends (grid) */
.difi-list {
    display: grid;
    grid-template-columns: 25% 25% 1fr 1fr 1fr 25px;
    clear: both;
}

.difi-list>div:not(.header-item):not(.expanded) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.difi-list h4 {
    margin-bottom: 0 !important;
}

.difi-list .header-item {
    grid-row: 1;
}

.list-item-label-boxes {
    grid-column: 1 / span 6;
    /*display: flex;*/
    /*flex-wrap: wrap;*/
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
}

.second-row {
    padding-top: 1.25em!important;
}

.difi-list .list-item-label-boxes .difi-input {
    display: inline-block;
}

.difi-list .list-item-actions svg {
    display: block;
    margin-top: 0.5em;
}

/* padding and margins */
.difi-list>div:first-child, .difi-list .first-column {
    padding-left: 0.5em;
    margin-left: 0.25em;
    padding-right: 0.5em; /* integration-id column needs a bit more space to the right*/
}

.difi-list .last-column {
    padding-right: 0.5em;
    margin-right: 0.25em;
}

.difi-list .first-row {
    padding-top: 0.5em;
    margin-top: 0.25em;
}

.difi-list .second-row {
    padding-top: 0.5em; /* adds a bit more space to the 'row' */
    padding-bottom: 0.5em;
    margin-bottom: 0.25em;
}


/* Borders around expanded list rows */
.difi-list .first-column.expanded {
    border-left: 1px solid lightgrey;
    padding-left: calc(0.5em - 1px); /* reduce padding width with border with - otherwise row 'jumps' 1px */
}

.difi-list .last-column.expanded {
    border-right: 1px solid lightgrey;
    padding-right: calc(0.5em - 1px);
}

.difi-list .first-row.expanded {
    border-top: 1px solid lightgrey;
    padding-top: calc(0.5em - 1px);
}

.difi-list .second-row.expanded {
    border-bottom: 1px solid lightgrey;
    padding-bottom: calc(0.5em - 1px);
}

.difi-list .first-row.first-column.expanded {
    border-top-left-radius: 0.5em;
}

.difi-list .first-row.last-column.expanded {
    border-top-right-radius: 0.5em;
}

.difi-list .second-row.first-column.expanded {
    border-bottom-left-radius: 0.5em;
}

.difi-list .second-row.last-column.expanded {
    border-bottom-right-radius: 0.5em;
}


/* unset stuff */
.difi-list a, .difi-list a:link, .difi-list a:visited, .difi-list a:hover, .difi-list a:active, .difi-list a:focus {
    text-decoration: none !important;
    background-color: unset !important;
    box-shadow: unset !important;
    color: unset !important;
    transition: none !important;
    border-bottom: none !important;
}

.difi-list a:hover {
    color: lightgrey !important;
}

.difi-operational {
    background-color: #ffefbd;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.clickable {
    cursor: pointer;
}

.clickable :hover {
    color: lightgrey !important;
}

.disabled {
    pointer-events: none;
    color: lightgrey !important;
}


/* Custom enforced_aud_for_access_token shoehorning */

#main .sp-web .custom-container {
    display: block;
    width: 100%;
}

#main .sp-web .custom-subcontrol {
    padding-left: 3em;
    display: block;
    width: 100%;
}

#main .sp-web .custom-subcontrol.disabled {
    opacity: 0.5;
}

#main .sp-web .custom-subcontrol .align-self-top {
    padding-top: 0.45em;
    align-self: flex-start;
    flex: 0 0 auto;
}

#main .sp-web .custom-subcontrol label {
    font-weight: normal;
    display: none;
}

#root form input[readonly]:hover {
    border: unset !important;
}

.light-bulb-parent {
    text-align: center;
    padding-right: 2px;
    /* padding-left: 3px; */
}

.light-bulb {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    /* top: 8px; */
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.6);
}


.green-bulb {
    background-color: #008000;
    background-image: radial-gradient(circle at 30% 30%, #008000, #006400 70%);
}

.red-bulb {
    background-color: #8B0000;
    background-image: radial-gradient(circle at 30% 30%, #f00, #c00 70%);
}

.light-bulb::after {
    content: '';
    position: absolute;
    top: 10%;
    left: 25%;
    width: 30%;
    height: 30%;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    transform: rotate(45deg);
}

#scopes-table td:first-child, #scopes-table td:nth-child(3) {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

#scopes-table td:first-child {
    width: 20%;
}

#scopes-table td:nth-child(3) {
    width: 35%;
}

.new-line {
    white-space: pre-line;
}

.content-article #content article #altinn-error-helper {
    display: inline-block;
    margin-top: 8px;
}

.content-article #content article #altinn-error {
    color: #F44336;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    line-height: 1em;
    padding: 2px 5px;
}

#login-error-back-button {
    margin-top: 10px;
}