/* ----------------------------------------------
 * Generated by Animista
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**************************************/
/*     toast                          */
/**************************************/
/* TODO: why doesn't this animation get triggered? */
.slide-toast-enter-active {
    animation: slide-left 300ms linear both;
    z-index: 99999;
}

@-webkit-keyframes slide-left {
    0% {
        transform: translateX(500px);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

.slide-toast-exit-active {
    animation: slide-right 300ms linear both;
    z-index: 99999;
}

@keyframes slide-right {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(500px);
        opacity: 0;
    }
}

/**************************************/
/*     edit mode buttons              */
/**************************************/
:root {
    --transition-speed: 250ms;
    --button-box-height: 153.5px;
    --button-box-short-height: 114.5px;
}


.fade-buttons-enter-active {opacity: 0;animation: fade-out var(--transition-speed) linear both;}
.fade-buttons-exit-active  {opacity: 1;animation: fade-in  var(--transition-speed) linear both;}
@keyframes fade-out {
    0% {opacity: 0;}
    20% {opacity: 0.90}
    100% {opacity: 1;}
}
@keyframes fade-in  {
    0% {opacity: 1;}
    80% {opacity: 0.10}
    100% {opacity: 0;}
}


.slide-buttons-enter {max-height: 0;}
.slide-buttons-short-enter {max-height: 0;}
.slide-buttons-enter-active {animation: slide-down var(--transition-speed) cubic-bezier(0.0, 0.0, 0.58, 1.0) both;}
.slide-buttons-short-enter-active {animation: slide-down-short var(--transition-speed) cubic-bezier(0.0, 0.0, 0.58, 1.0) both;}
.slide-buttons-enter-done {}
.slide-buttons-short-enter-done {}

.slide-buttons-exit-active  {animation: slide-up   var(--transition-speed) linear both;}
.slide-buttons-exit-active-short  {animation: slide-up-short   var(--transition-speed) linear both;}

@keyframes slide-down {0% {max-height: 0;}                       100% {max-height: var(--button-box-height);}}
@keyframes slide-up   {0% {max-height: var(--button-box-height);} 100% {max-height: 0;}}

@keyframes slide-down-short {0% {max-height: 0;}                       100% {max-height: var(--button-box-short-height);}}
@keyframes slide-up-short   {0% {max-height: var(--button-box-short-height);} 100% {max-height: 0;}}

/**************************************/
/*    fade effect                     */
/**************************************/
.fade-enter-active {opacity: 0;animation: fade-out var(--transition-speed) linear both;}
.fade-exit-active  {opacity: 1;animation: fade-in  var(--transition-speed) linear both;}
@keyframes fade-out {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
@keyframes fade-in  {
    0% {opacity: 1;}
    100% {opacity: 0;}
}
