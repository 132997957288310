/* missing from bootstrap.css? */
.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    font-weight: normal!important;
}
.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.custom-control-input:checked~.custom-control-label:before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
}

.custom-switch .custom-control-label:before {
    left: -2.25em;
    width: 1.75em;
    pointer-events: all;
    border-radius: .5em;
}
.custom-control-label:before, .custom-file-label, .custom-select {
    -webkit-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.custom-control-label:after, .custom-control-label:before {
    position: absolute;
    top: .25em;
    left: -1.5em;
    display: block;
    width: 1em;
    height: 1em;
    content: "";
}
.custom-control-label:before {
    pointer-events: none;
    background-color: #fff;
    border: 1px solid #adb5bd;
}

.custom-switch .custom-control-input:checked~.custom-control-label:after {
    background-color: #fff;
    -webkit-transform: translateX(.75em);
    transform: translateX(.75em);
}
.custom-switch .custom-control-label:after {
    top: calc(.25em + 2px);
    left: calc(-2.25em + 2px);
    width: calc(1em - 4px);
    height: calc(1em - 4px);
    background-color: #adb5bd;
    border-radius: .5em;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
    -webkit-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
    transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
}

.custom-control-label:after {
    background: no-repeat 50%/50% 50%;
}
.custom-control-label:after, .custom-control-label:before {
    position: absolute;
    top: .25em;
    left: -1.5em;
    display: block;
    width: 1em;
    height: 1em;
    content: "";
}

.custom-switch {
    padding-left: 2.25em;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5em;
    padding-left: 1.5em;
}
